import { configureStore } from "@reduxjs/toolkit";
import themeSlice, { toggleTheme } from "../Features/CommonSlice.js";

const store = configureStore({
  reducer: {
    theme: themeSlice.reducer,
  },
});

const savedDarkMode = JSON.parse(localStorage.getItem("seventh_journal_theme"));
if (savedDarkMode !== null) {
  store.dispatch(toggleTheme(savedDarkMode));
}

export default store;
