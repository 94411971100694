// ThemeSetter.js
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { alwaysFalsePaths, alwaysTruePaths } from "../commonutilities";
import { useLocation } from "react-router-dom";

const ThemeSetter = () => {
  // const darkMode = useSelector((state) => state.theme.darkMode);

  // const pathAfterAuth = window.location.href;
  let theme = {
    seventhsight: true,
    seventharc: false,
    seventhjournal: false,
  };
  const pathAfterAuth = new URL(window.location.href).pathname;
  let darkMode = true;
  if (alwaysTruePaths.some((path) => pathAfterAuth.includes(path))) {
    theme =
      JSON.parse(localStorage.getItem("seventh_journal_theme")) !== null
        ? JSON.parse(localStorage.getItem("seventh_journal_theme"))
            ?.seventhjournal
        : false;
    darkMode = theme;
  } else if (alwaysFalsePaths.some((path) => pathAfterAuth.includes(path))) {
    darkMode = theme?.seventharc;
  } else {
    darkMode = true;
  }
  useEffect(() => {
    // Define the CSS variables based on the theme mode
    const root = document.documentElement;
    root.style.setProperty("--white", darkMode ? "white" : "white");
    root.style.setProperty("--background", darkMode ? "#050927" : "white");
    root.style.setProperty(
      "--text-color-black",
      darkMode ? "#ffffff" : "#050927"
    );
    root.style.setProperty("--black--white", darkMode ? "#050927" : "#ffffff");
    root.style.setProperty("--primary", "#7447f7");
    root.style.setProperty(
      "--secondary",
      darkMode ? "rgba(255, 255, 255, 0.1)" : "#e7dcff"
    );
    root.style.setProperty(
      "--container-bg-color",
      darkMode ? "#050927" : "#ffffff"
    );
    root.style.setProperty("--main-23", darkMode ? "#373A52" : "#C7B5FC");
    root.style.setProperty("--main-33", darkMode ? "#696B7D" : "#AC91FA");
    root.style.setProperty("--main-22", darkMode ? "#AC91FA" : "#7447F7");
    root.style.setProperty("--primary-white", darkMode ? "#ffffff" : "#7447f7");
    root.style.setProperty("--grey-text", darkMode ? "#9B9DA9" : "#696b7d");
    root.style.setProperty("--accent-7", darkMode ? "#0CD5DC" : "#078084");
    root.style.setProperty("--accent-81", darkMode ? "#055558" : "#CEF7F8");
    root.style.setProperty("--main-25", darkMode ? "#C7B5FC" : "#7447f7");
    root.style.setProperty("--main-61", darkMode ? "#5D39C6" : "#E3DAFD");
    root.style.setProperty("--main-2-61", darkMode ? "#7447F7" : "#E3DAFD");
    root.style.setProperty(
      "--main-2-white-3",
      darkMode ? "rgba(255,255,255,0.3)" : "rgba(227, 218, 253, 1)"
    );
    root.style.setProperty(
      "--main-3-white-2",
      darkMode ? "rgba(255,255,255,0.2)" : "rgba(227, 218, 253, 0.3)"
    );
    root.style.setProperty(
      "--white-light-grey",
      darkMode ? "#ffffff" : "#CDCED4"
    );
    root.style.setProperty(
      "--white-transparent",
      darkMode ? "rgba(255,255,255,0.1)" : "#F6F1FE"
    );
    root.style.setProperty("--off-black", darkMode ? "#A0A4D6" : "#000000");
    root.style.setProperty(
      "--white-full-transparent",
      darkMode ? "rgba(255,255,255,0)" : "#ffffff"
    );
    root.style.setProperty(
      "--white-white-3",
      darkMode ? "rgba(255,255,255,0.3)" : "#ffffff"
    );
    root.style.setProperty(
      "--white-white-1",
      darkMode ? "rgba(255,255,255,0.1)" : "#ffffff"
    );
    root.style.setProperty(
      "--white-white-2",
      darkMode ? "rgba(255,255,255,0.1)" : "#ffffff"
    );
    root.style.setProperty(
      "--main-05-white-2",
      darkMode ? "rgba(255,255,255,0.2)" : "rgba(116,71,247,0.05)"
    );
    root.style.setProperty(
      "--main-2-white-1",
      darkMode ? "rgba(255,255,255,0.1)" : "rgba(199, 181, 252, 1)"
    );
    root.style.setProperty(
      "--white-2-accent-2",
      darkMode ? "rgba(255,255,255,0.2)" : "rgba(206, 247, 248, 1)"
    );
    root.style.setProperty("--white-dark", darkMode ? "#050927" : "#ffffff");
    root.style.setProperty("--dark-white", darkMode ? "#ffffff" : "#050927");
  }, [darkMode]);

  return null;
};

export default ThemeSetter;
