import axios from "axios";
import { chatbot_url } from "../bootapi";
import { ngrok_url } from "../bootapi";

export const callfirstques = (username, token, id) => {
  let formdata = new FormData();
  formdata.append("username", username);
  formdata.append("user_id", id);
  return axios.post(`${chatbot_url}/get_first_question`, formdata, {
    headers: { Authorization: `${token}` },
  });
};
export const callfirstquesall = (username, token, id) => {
  let formdata = new FormData();
  formdata.append("username", username);
  formdata.append("user_id", id);
  return axios.post(`${chatbot_url}/get_first_question_all`, formdata, {
    headers: { Authorization: `${token}` },
  });
};

export const callnextques = (username, data, token, id) => {
  let formdata = new FormData();
  formdata.append("user_id", id);
  formdata.append("username", username);
  formdata.append("prev_question", JSON.stringify(data));
  return axios.post(`${chatbot_url}/get_next_question`, formdata, {
    headers: { Authorization: `${token}` },
  });
};
export const callnextquesall = (username, data, token, id) => {
  let formdata = new FormData();
  formdata.append("user_id", id);
  formdata.append("username", username);
  formdata.append("prev_question", JSON.stringify(data));
  return axios.post(`${chatbot_url}/get_next_question_all`, formdata, {
    headers: { Authorization: `${token}` },
  });
};

export const callQueryAnswer = (data) => {
  return axios.post(`${ngrok_url}/sight/customer_query`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getTotalChats = (token, id) => {
  let formdata = new FormData();
  formdata.append("user_id", id);
  return axios.post(`${chatbot_url}/get_total_chats`, formdata, {
    headers: { Authorization: `${token}` },
  });
};

//Guest chatbot apis
export const callfirstquesguest = (unique_id) => {
  let formdata = new FormData();
  formdata.append("unique_id", JSON.stringify(unique_id));
  return axios.post(`${chatbot_url}/get_first_question_guest`, formdata);
};
export const callfirstquesallguest = (unique_id) => {
  let formdata = new FormData();
  formdata.append("unique_id", JSON.stringify(unique_id));
  return axios.post(`${chatbot_url}/get_first_question_all_guest`, formdata);
};

export const callnextquesguest = (data, uuid) => {
  let formdata = new FormData();
  formdata.append("prev_question", JSON.stringify(data));
  formdata.append("unique_id", JSON.stringify(uuid));
  return axios.post(`${chatbot_url}/get_next_question_guest`, formdata);
};
export const callnextquesallguest = (data, uuid) => {
  let formdata = new FormData();
  formdata.append("prev_question", JSON.stringify(data));
  formdata.append("unique_id", JSON.stringify(uuid));
  return axios.post(`${chatbot_url}/get_next_question_all_guest`, formdata);
};
