import axios from "axios";
import moment from "moment";
import { supabase } from "..";
import { ngrok_url, payment_url } from "../bootapi";
import {
  getDreamsData,
  insertDreamDetails,
  updateDreamDetails,
  updateDreamsData,
} from "./paymentService";

export const getUserById = (userId, token) => {
  return axios.get(`${payment_url}/subsapp/subs_users?user_id=${userId}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const geoLocation = () => {
  return axios.get("https://api.ipify.org?format=json");
};
export const getDreamAnalysis = async (obj, guest) => {
  let newJsonData = {
    dream: obj["dream"],
    email: obj["email"],
    important_scale: obj["important"],
    vivid_scale: obj["vived"],
    email: obj["email"],
  };
  if (!guest) {
    newJsonData = { ...newJsonData, user_id: obj["user_id"] };
  }
  if (obj["chatid"]) {
    newJsonData = { ...newJsonData, chatbot_data_id: obj["chatid"] };
  }
  let url = "sight/analysis";
  if (guest) {
    url = obj?.freemium ? "sight/analysis_freemium" : "sight/analysis_guest";
    if (obj?.freemium) {
      newJsonData = {
        ...newJsonData,
        temporary_user_id: obj?.temporary_user_id,
      };
    }
  }
  return axios.post(`${ngrok_url}/${url}`, JSON.stringify(newJsonData), {
    headers: {
      Authorization: `${obj["token"]}`,
      "Content-Type": "application/json",
    },
  });
};

export const supabaseGuest = (body, id) => {
  supabase
    .from("guests")
    .update(body)
    .eq("id", id)
    .select()
    .then((res) => {});
};
export const supabaseAnalysis = async (body, id, userId, newToken) => {
  const updateData = {
    update: {
      ...body,
    },
    eq: {
      id,
    },
  };
  const { data: sessionData, error } = await supabase.auth.getSession();

  updateDreamsData(updateData, newToken).then((res) => {
    if (res?.data?.[0]) {
      if (
        res.data?.[0]?.advice &&
        res.data?.[0]?.analysis &&
        res.data?.[0]?.insights
      ) {
        let data = {
          user_id: userId,
        };
        // sendCredit(JSON.stringify(data));
      }
    }
  });
};
export const supabaseDreamData = (id, newToken, userId) => {
  const requestData = {
    select:
      "id,created_at,dream,journalDate,advice,insights,guest_save,analysis,image,dream_details(*)",
    eq: {
      id: id,
      user_id: userId,
    },
  };

  return getDreamsData(requestData, newToken);
};

export const getDreamInsight = async (
  res,
  state,
  setcurrentinsightholder,
  setcurrentinsightimageholder,
  guest,
  setloader
) => {
  let newJsonData = {
    symbol_data: res,
    dream: state?.dream,
    email: state?.email,
    important_scale: state["important"],
    vivid_scale: state["vived"],
    emotion: "none",
  };

  if (!guest) {
    newJsonData = {
      ...newJsonData,
      image_name: `insight_${state.dreamDataId}`,
      user_id: state.user_id,
      journalDate: moment(state.date).format("YYYY-MM-DDThh:mm:ss"),
      seventhjournal_subscription: state.seventhjournal,
    };
  }
  if (state.chatid) {
    newJsonData = { ...newJsonData, chatbot_data_id: state.chatid };
  }
  let url = "sight/insights";
  if (guest) {
    url = `sight/insights_${state?.freemium ? "freemium" : "guest"}`;
    if (state?.freemium) {
      newJsonData = {
        ...newJsonData,
        temporary_user_id: state?.temporary_user_id,
      };
    }
  }

  let imageObjData = {
    dream: state?.dream,
    image_name: `insight_${state.dreamDataId}`,
  };
  if (!guest) {
    getInsightsImage(imageObjData)
      .then((res) => {
        if (res?.data?.image_supbase) {
          setcurrentinsightimageholder({
            data: {
              image: res?.data?.image_supbase,
            },
            id: state["dreamDataId"],
          });
          supabaseAnalysis(
            {
              image: JSON.stringify(res?.data?.image_supbase),
            },
            state?.dreamDataId,
            state?.user_id,
            state?.token
          );
        } else {
          setcurrentinsightimageholder({
            data: { error: true },
            id: state["dreamDataId"],
          });
        }
      })
      .catch((err) => {
        setcurrentinsightimageholder({
          data: { error: true },
          id: state["dreamDataId"],
        });
      });
  }
  axios
    .post(`${ngrok_url}/${url}`, JSON.stringify(newJsonData), {
      headers: {
        Authorization: `${state["token"]}`,
        "Content-Type": "application/json",
      },
    })
    .then((res2) => {
      if (res2.status === 200) {
        let body = {};
        body["insights"] = JSON.stringify(res2.data);
        if (guest) {
          if (!state?.["freemium"]) {
            supabaseGuest(body, state.dreamDataId);
          } else {
            let existingData = JSON.parse(
              sessionStorage.getItem("SeventhSightFreemium")
            );

            existingData.image = res2.data?.image;

            sessionStorage.setItem(
              "SeventhSightFreemium",
              JSON.stringify(existingData)
            );
          }
        } else {
          supabaseAnalysis(
            body,
            state?.dreamDataId,
            state?.user_id,
            state?.token
          );
        }
        setcurrentinsightholder({
          data: res2.data,
          id: state["dreamDataId"],
        });
      } else {
        setcurrentinsightholder({
          data: { error: true },
          id: state["dreamDataId"],
        });
      }
      if (setloader) setloader(false);
    })
    .catch((err) => {
      if (setloader) setloader(false);
      setcurrentinsightholder({
        data: { error: true },
        id: state["dreamDataId"],
      });
    });
};
export const getInsightsConnections = (
  res,
  state,
  setDeeperInsights,
  guest,
  setloader
) => {
  let newJsonData = {
    symbol_data: res,
    dream: state?.dream,
    email: state?.email,
    emotion: "none",
    image_name: `insight_${state.dreamDataId}`,
    user_id: state.user_id,
    journalDate: moment(state.date).format("YYYY-MM-DDThh:mm:ss"),
  };
  let url = "sight/insights_only_connections";

  axios
    .post(`${ngrok_url}/${url}`, JSON.stringify(newJsonData), {
      headers: {
        Authorization: `${state["token"]}`,
        "Content-Type": "application/json",
      },
    })
    .then((res2) => {
      if (res2.status === 200) {
        setDeeperInsights({
          data: res2.data,
          id: state["dreamDataId"],
        });
      } else {
        setDeeperInsights({
          data: { error: true },
          id: state["dreamDataId"],
        });
      }
      //This is pending and needs to be done
      /* let body = {};
      body["insights_only_connections"] = JSON.stringify(res2.data);

      supabaseAnalysis(body, state?.dreamDataId,state?.token); */
    })
    .catch((err) => {
      if (setloader) setloader(false);
      setDeeperInsights({
        data: { error: true },
        id: state["dreamDataId"],
      });
    });
};

export const getDreamAdvice = (res, state, setcurrentadviceholder, guest) => {
  let newJsonData = {
    symbol_data: res,
    dream: state?.dream,
    email: state?.email,
    emotion: "none",
    important_scale: state["important"],
    vivid_scale: state["vived"],
  };

  if (!guest) {
    newJsonData = {
      ...newJsonData,
      user_id: state.user_id,
      journal_date: moment(state.date).format("YYYY-MM-DDThh:mm:ss"),
      seventhjournal_subscription: state.seventhjournal,
    };
  }
  if (state.chatid) {
    newJsonData = { ...newJsonData, chatbot_data_id: state.chatid };
  }
  let url = "sight/advice";
  if (guest) url = `sight/advice_${state?.freemium ? "freemium" : "guest"}`;
  axios
    .post(`${ngrok_url}/${url}`, JSON.stringify(newJsonData), {
      headers: {
        Authorization: `${state["token"]}`,
        "Content-Type": "application/json",
      },
    })
    .then((res2) => {
      if (res2.status === 200) {
        let body = {};
        if (guest) {
          body["advice"] = res2.data;
          if (!state?.["freemium"]) {
            supabaseGuest(body, state?.dreamDataId);
          }
        } else {
          body["advice"] = JSON.stringify(res2.data);
          supabaseAnalysis(
            body,
            state?.dreamDataId,
            state.user_id,
            state?.token
          );
        }
        setcurrentadviceholder({ data: res2.data, id: state["dreamDataId"] });
      } else {
        setcurrentadviceholder({
          data: { error: true },
          id: state["dreamDataId"],
        });
      }
    })
    .catch((err) => {
      setcurrentadviceholder({
        data: { error: true },
        id: state["dreamDataId"],
      });
    });
};
export const dreamsDetail = async (
  obj,
  setbkpdetail,
  setcurrentinsightholder,
  setcurrentadviceholder,
  setcurrentinsightimageholder,
  guest,
  tempObj,
  index
) => {
  let newJsonData = {
    dream: obj["dream"],
    email: obj["email"],
    important_scale: obj["important"],
    vivid_scale: obj["vived"],
  };

  if (!guest) {
    newJsonData = { ...newJsonData, user_id: obj["user_id"] };
  }

  var requestOptions = {
    method: "POST",
    body: JSON.stringify(newJsonData),
    redirect: "follow",
  };
  let headers = {
    Authorization: `${obj["token"]}`,
    "Content-Type": "application/json",
  };
  requestOptions["headers"] = headers;
  let url = "sight/details";
  if (guest) url = obj?.freemium ? url + "_freemium" : url + "_guest";
  try {
    const response = await fetch(`${ngrok_url}/${url}`, requestOptions);
    const result = await response.json();
    let data = [];
    if (typeof result === "object" && result.length) {
      for (let i = 0; i < result.length; i++) {
        if (result[i].tab === "details") {
          data = JSON.parse(JSON.stringify(result[i].information));
          break;
        }
      }
    }
    let data2 = [];
    data.forEach((ele) => {
      if (typeof ele["value"] === "object") {
        data2.push({
          keyword: ele["keyword"],
          data: ele["value"],
          value: ele["value"]?.[0],
          id: "",
        });
      }
    });
    if (data2.length > 0 && !guest) {
      let body = [];
      data2.forEach((ele) => {
        body.push({
          dream_id: obj["dreamDataId"],
          dream_result: ele["value"],
          email: obj["email"],
          keyword: ele["keyword"],
          date: moment().format("YYYY-MM-DD"),
          user_id: obj["user_id"],
          status: "",
        });
      });

      const { data: sessionData, error } = await supabase.auth.getSession();

      const requestData = {
        insert: body,
      };

      insertDreamDetails(requestData, obj?.token).then((res) => {
        if (res) {
          let data3 = JSON.parse(JSON.stringify(data2));
          if (typeof res["data"] === "object" && res["data"].length > 0) {
            res["data"].forEach((ele, index) => {
              data3[index]["id"] = ele["id"];
            });
          }
          data2 = JSON.parse(JSON.stringify(data3));
          let detailobj = { [obj["dreamDataId"]]: data2 };

          setbkpdetail((bkpdetail) => {
            setDetails({ ...bkpdetail, ...detailobj }, index);
            return { ...bkpdetail, ...detailobj };
          });
          if (tempObj?.insights) {
            getDreamInsight(
              data2,
              obj,
              setcurrentinsightholder,
              setcurrentinsightimageholder
            );
          }
          if (tempObj?.advice) {
            getDreamAdvice(data2, obj, setcurrentadviceholder);
          }

          return data2;
        } else {
          let detailobj = { [obj["dreamDataId"]]: data2 };
          setbkpdetail((bkpdetail) => {
            setDetails({ ...bkpdetail, ...detailobj }, index);
            return { ...bkpdetail, ...detailobj };
          });
          if (tempObj?.insights) {
            getDreamInsight(
              data2,
              obj,
              setcurrentinsightholder,
              setcurrentinsightimageholder
            );
          }
          if (tempObj?.advice) {
            getDreamAdvice(data2, obj, setcurrentadviceholder);
          }

          return data2;
        }
      });
    } else if (data.length > 0) {
      let detailobj = !obj?.["freemium"]
        ? { [obj["dreamDataId"]]: data2 }
        : { [1]: data2 };
      let body = { detail: data2 };
      if (!obj?.["freemium"]) {
        supabaseGuest(body, obj.dreamDataId);
      }
      setbkpdetail((bkpdetail) => {
        setDetails({ ...bkpdetail, ...detailobj }, index, obj?.["freemium"]);
        return { ...bkpdetail, ...detailobj };
      });
      getDreamInsight(
        data2,
        obj,
        setcurrentinsightholder,
        setcurrentinsightimageholder,
        guest
      );
      getDreamAdvice(data2, obj, setcurrentadviceholder, guest);

      return data2;
    } else {
      let detailobj = { [obj["dreamDataId"]]: data2 };

      setbkpdetail((bkpdetail) => {
        setDetails({ ...bkpdetail, ...detailobj }, index);
        return { ...bkpdetail, ...detailobj };
      });
      setcurrentinsightholder({
        data: { error: true },
        id: obj["dreamDataId"],
      });
      setcurrentadviceholder({
        data: { error: true },
        id: obj["dreamDataId"],
      });
    }
  } catch (error) {
    let detailobj = { [obj["dreamDataId"]]: [] };
    setbkpdetail((bkpdetail) => {
      return { ...bkpdetail, ...detailobj };
    });
    setcurrentinsightholder({ data: { error: true }, id: obj["dreamDataId"] });
    setcurrentadviceholder({ data: { error: true }, id: obj["dreamDataId"] });
    return [];
  }
};
const setDetails = (updatedDreamDetails, index, freemium = false) => {
  if (!freemium) {
    const existingData =
      JSON.parse(localStorage.getItem("SeventhSightDreamAnalysis")) || {};
    existingData[index].dream_details = updatedDreamDetails;

    localStorage.setItem(
      "SeventhSightDreamAnalysis",
      JSON.stringify(existingData)
    );
  }
};

export const detailAgree = async (id, newToken) => {
  const { data: sessionData, error } = await supabase.auth.getSession();

  const updateData = {
    update: { agree: true },
    eq: {
      id: id,
    },
  };
  updateDreamDetails(updateData, newToken).then((res) => {});
};
export const detailDisAgree = async (
  detailtab,
  setDetail,
  index,
  data_id,
  em,
  bkpdetail,
  user_id,
  newToken
) => {
  const { data: sessionData, error } = await supabase.auth.getSession();
  let data = JSON.parse(JSON.stringify(detailtab));

  const updateData = {
    update: { disagree: true, agree: false },
    eq: {
      id: detailtab[index]["id"],
    },
  };
  updateDreamDetails(updateData, newToken).then((res) => {
    if (res) {
      let data2 = JSON.parse(JSON.stringify(data));
      data[index]["data"].shift();
      if (data[index]["data"].length === 0) {
        data2[index]["status"] = "disagree";
        let obj56 = { [data_id]: data2 };
        setDetail({ ...bkpdetail, ...obj56 });
        return;
      }
      data[index]["value"] = data[index]["data"][0];
      const body = {
        dream_id: data_id,
        dream_result: data[index]["value"], //data2[Object.keys(data2)[0]][0]["value"],
        email: em,
        user_id,
        keyword: data[index]["keyword"], //Object.keys(data2)[0],
      };
      const requestData = {
        insert: body,
      };

      insertDreamDetails(requestData, newToken).then((res) => {
        if (res) {
          data[index]["id"] = res.data[0].id;
          let obj56 = { [data_id]: data };
          setDetail({ ...bkpdetail, ...obj56 });
        }
      });
    }
  });
};

export const sendGuestAnalysis = (formdata) => {
  return axios.post(
    `${ngrok_url}/sight/send_guest_analysis
  `,
    formdata,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const getInsightsImage = (data, flag = false) => {
  if (flag) {
    return null;
  }
  return axios.post(
    `${ngrok_url}/sight/insights_only_image
  `,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const sendCredit = (data) => {
  return axios.post(
    `${ngrok_url}/sight/decrease_free_credits
  `,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const saveFreemiumDream = (data) => {
  return axios.post(
    `${ngrok_url}/sight/save_freemium_dream
  `,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateGuestDataById = (id, token) => {
  return axios.post(`${ngrok_url}/sight/guest_data?id=${id}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateGuestDataByEmail = (email, token) => {
  return axios.post(`${ngrok_url}/sight/guest_data?email=${email}`, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateGuestData = (data, token) => {
  return axios.post(
    `${ngrok_url}/sight/update_guest_data
  `,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const saveGuestDataToPrev = (data) => {
  return axios.post(
    `${ngrok_url}/sight/save_guest_data_to_previous_dream
  `,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getDreamArcana = (data, token) => {
  return axios.post(
    `${ngrok_url}/sight/your_dream_arcana
  `,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getDreamFrequency = (data, token) => {
  return axios.post(
    `${ngrok_url}/sight/dream_frequency
  `,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getPopularSymbols = () => {
  return axios.post(
    `${ngrok_url}/sight/most_popular_symbols
  `
  );
};
export const getPopularEmotions = () => {
  return axios.post(
    `${ngrok_url}/sight/most_popular_emotion
  `
  );
};

export const getTextToSpeech = (data, token) => {
  return axios.post(
    `${ngrok_url}/sight/text_to_speech
  `,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const generateDreamTitle = (data, token) => {
  return axios.post(
    `${ngrok_url}/sight/generate_title_while_save
  `,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getFuturePredictions = () => {
  return axios.post(
    `${ngrok_url}/sight/future_prediction_score
  `
  );
};
export const uploadVoiceRecognition = (formData, token) => {
  return axios.post(
    `${ngrok_url}/sight/speech_emotion_recognition
  `,
    formData,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};
export const getGuestAnalysisData = (data, token) => {
  return axios.post(
    `${ngrok_url}/sight/get_subs_guestdata
  `,
    data,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};
export const updateGuestDataUser = (data) => {
  return axios.post(
    `${ngrok_url}/sight/update_subs_guestdata
  `,
    data
  );
};

export const getNotificationDetails = (token, email) => {
  return axios.get(
    `${payment_url}/subsapp/notification_preference?user=${email}`,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const createNotificationDetails = (token, data) => {
  return axios.post(`${payment_url}/subsapp/notification_preference`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateNotificationDetails = (token, email, data) => {
  return axios.put(
    `${payment_url}/subsapp/notification_preference?user=${email}`,
    data,
    {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getInsightPrevDream = async (state, token) => {
  let newJsonData = {
    symbol_data: state.symbol_data,
    dream: state?.dream,
    email: state?.email,
    important_scale: state["important"],
    vivid_scale: state["vived"],
    emotion: "none",
    image_name: `insight_${state.dreamDataId}`,
    user_id: state.user_id,
    journalDate: moment(state.journal_date).format("YYYY-MM-DDThh:mm:ss"),
    seventhjournal_subscription: state.seventhjournal,
  };

  let url = "sight/insights";

  try {
    const res2 = await axios.post(
      `${ngrok_url}/${url}`,
      JSON.stringify(newJsonData),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (res2.status === 200) {
      let body = {
        insights: JSON.stringify(res2.data),
      };

      let isFreemium = res2?.data?.freemium ? true : false;

      if (isFreemium) {
        return { data: { freemium: true }, id: state["dreamDataId"] };
      }

      await supabaseAnalysis(body, state?.dreamDataId, state?.user_id, token);

      return {
        data: res2.data,
        id: state["dreamDataId"],
      };
    } else {
      return {
        data: { error: true },
        id: state["dreamDataId"],
      };
    }
  } catch (err) {
    return {
      data: { error: true },
      id: state["dreamDataId"],
    };
  }
};

export const getAdvicePrevDream = async (state, token) => {
  let newJsonData = {
    symbol_data: state.symbol_data,
    dream: state?.dream,
    email: state?.email,
    emotion: "none",
    important_scale: state["important"],
    vivid_scale: state["vived"],
    user_id: state.user_id,
    journal_date: moment(state.journal_date).format("YYYY-MM-DDThh:mm:ss"),
    seventhjournal_subscription: state.seventhjournal,
  };

  if (state?.chatid) {
    newJsonData = { ...newJsonData, chatbot_data_id: state.chatid };
  }

  let url = "sight/advice";

  try {
    const res2 = await axios.post(
      `${ngrok_url}/${url}`,
      JSON.stringify(newJsonData),
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (res2.status === 200) {
      let body = {};
      body["advice"] = JSON.stringify(res2.data);
      let isFreemium = res2?.data?.freemium ? true : false;

      if (isFreemium) {
        return { data: { freemium: true }, id: state["dreamDataId"] };
      }
      supabaseAnalysis(body, state?.dreamDataId, state.user_id, token);
      return { data: res2.data, id: state["dreamDataId"] };
    } else {
      return {
        data: { error: true },
        id: state["dreamDataId"],
      };
    }
  } catch (err) {
    return {
      data: { error: true },
      id: state["dreamDataId"],
    };
  }
};
