const DEVELOPMENT_MODE = false;

export let ngrok_url,
  arc_ngrok_url,
  journal_ngrok_url,
  payment_url,
  chatbot_url,
  website_url,
  plan_type_prod,
  showSeventhArc,
  new_functionality,
  confShowCare,
  confShowGuide,
  localHost;

// *****************************************************
// *****************************************************
// **************a***************************************
// *****************************************************
// *****************************************************
// *****************************************************
if (DEVELOPMENT_MODE === true) {
  // Test URLs
  ngrok_url = "https://devapi.seventhsight.com";
  arc_ngrok_url = "https://devapiarch.seventhsight.com/arc";
  journal_ngrok_url = "https://devapijournal.seventhsight.com";
  payment_url = "https://devpaymentapi.seventhsight.com";
  chatbot_url = "https://devapichatbot.seventhsight.com";
  website_url = "https://dev.seventhsight.com";
  plan_type_prod = false;
  showSeventhArc = true;
  new_functionality = true;
  localHost = true;
  confShowCare = true;
  confShowGuide = true;
} else {
  // Production URLs
  ngrok_url = "https://api.seventhsight.com";
  arc_ngrok_url = "https://apiarch.seventhsight.com/arc";
  journal_ngrok_url = "https://apijournal.seventhsight.com";
  payment_url = "https://paymentapi.seventhsight.com";
  chatbot_url = "https://apichatbot.seventhsight.com";
  website_url = "https://www.seventhsight.com";
  plan_type_prod = true;
  showSeventhArc = false;
  new_functionality = false;
  localHost = false;
  confShowCare = false;
  confShowGuide = false;
}
