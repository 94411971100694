import { createSlice } from "@reduxjs/toolkit";

const defaultTheme = {
  seventhsight: true,
  seventharc: false,
  seventhjournal: false,
};

let savedTheme;
const savedThemeStr = localStorage.getItem("seventh_journal_theme");

if (savedThemeStr) {
  try {
    savedTheme = JSON.parse(savedThemeStr);
  } catch (e) {
    savedTheme = null;
  }
}

const savedToken = localStorage.getItem("seventh_new_token");
const initialState = {
  darkMode:
    savedTheme && typeof savedTheme === "object" && !Array.isArray(savedTheme)
      ? savedTheme
      : defaultTheme,
  token: savedToken || null,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state, action) => {
      state.darkMode = { ...state.darkMode, ...action.payload };
      localStorage.setItem(
        "seventh_journal_theme",
        JSON.stringify(state.darkMode)
      );
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("seventh_new_token", action.payload);
    },
    clearToken: (state) => {
      state.token = null;
      localStorage.removeItem("seventh_new_token");
    },
  },
});

export const { toggleTheme, setToken, clearToken } = themeSlice.actions;

export default themeSlice;
