import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { Col } from "react-bootstrap";
import { RoundButtonDark, TrendingDreamSlider } from "../commonutilities";

const PopularSymbols = ({ popularSymbols, imageLoaded, handleImageLoaded }) => {
  const openlink = (link) => {
    window.open(link, "_blank");
  };
  return (
    <Swiper {...TrendingDreamSlider}>
      {popularSymbols &&
        popularSymbols.map((element, index) => (
          <SwiperSlide key={index}>
            <Col>
              <div className="dream-box">
                <div className="dream-box-inner">
                  <div className="dream-box-front">
                    {!imageLoaded[element?.image] && (
                      <div
                        className="newloadercontainerForDiv"
                        style={{ position: "relative" }}
                      >
                        <span className="new_loaderForDiv"></span>
                      </div>
                    )}
                    <img
                      className="width-height-100"
                      src={element?.image}
                      alt="img"
                      loading="lazy"
                      onLoad={() => handleImageLoaded(element?.image)}
                    />
                    <div className="dream-title-box">
                      <h4>
                        #{element?.rank} {element?.symbol}
                      </h4>
                    </div>
                  </div>
                  <div className="dream-box-back">
                    <img src={element?.image} alt="img" loading="lazy" />
                    <div className="dream-box-back-innerbox">
                      <h3>
                        #{element?.rank} {element?.symbol}
                      </h3>
                      <p>{element?.description}</p>
                      <span onClick={() => openlink(element?.url)}>
                        View More
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </SwiperSlide>
        ))}
      <SliderButtons />
    </Swiper>
  );
};

export default PopularSymbols;
const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="flexCenter w-100 cg-1" style={{ marginTop: "1rem" }}>
      <RoundButtonDark
        type={"backward"}
        filled={false}
        onClick={() => {
          swiper.slidePrev();
        }}
      />
      <RoundButtonDark
        type={"forward"}
        filled={false}
        onClick={() => {
          swiper.slideNext();
        }}
      />
    </div>
  );
};
