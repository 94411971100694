import Lottie from "lottie-react";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LottieData from "./../shared/Animation/SignupAnimation.json";

const ConfirmationMailModal = ({ darkMode, setShowPopup, showPopup, flag }) => {
  function toggleModalSignupSentMail() {
    setShowPopup(!showPopup);
  }
  return (
    <Modal
      isOpen={showPopup}
      toggle={() => toggleModalSignupSentMail()}
      style={
        window.innerWidth > 600
          ? {
              // position: 'absolute',
              top: "50%",
              transform: "translateY(-50%)",
            }
          : {
              top: "0%",
              transform: "translateY(0%)",
            }
      }
      className={`login-seventh journal-login-modal ${
        darkMode ? "journal-dark-button-theme" : "journal-light-button-theme"
      }`}
    >
      {/* <div className="flexColCenter al-center ">
              <img
                className="icon-48"
                style={{ flex: 1, paddingTop: "30px" }}
                src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/CheckedImage.png?t=2024-01-09T11%3A07%3A29.460Z"
                alt=""
              />
            </div> */}

      <Lottie
        className="flexColCenter al-center lottie-signup-confirm w-100"
        animationData={LottieData}
        loop={true}
      />

      <ModalHeader
        toggle={() => toggleModalSignupSentMail()}
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        {flag ? (
          <>
            {" "}
            Your Dream is
            <br /> Now Saved!
          </>
        ) : (
          "Check your email"
        )}
      </ModalHeader>
      <ModalBody style={{ paddingTop: "0px" }}>
        <p style={{ textAlign: "center", color: darkMode ? "white" : "black" }}>
          {flag ? (
            <span style={{ color: darkMode ? "white" : "black" }}>
              Check your email!
              <br />
              We sent you a temporary password to start your free trial with
              unlimited features!
            </span>
          ) : (
            "We sent you a temporary password to start your free trial with unlimited features!"
          )}
        </p>
      </ModalBody>
    </Modal>
  );
};
export default ConfirmationMailModal;
